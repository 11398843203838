// @flow

import type { Element } from 'react'
import React from 'react'

import MenuIcon from './icons/MenuIcon'

type Props = {
  onClick: () => void,
}

function MenuButton({ onClick }: Props): Element<'button'> {
  return (
    <button type="button" onClick={onClick}>
      <MenuIcon className="text-white" />
    </button>
  )
}

export default MenuButton
