// @flow

import type { Node, ComponentType } from 'react'
import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import Helmet from 'react-helmet'
import cx from 'classnames'

import theme from '../../../tailwind'

import HeaderMenu from '../header-menu'
import MenuButton from '../menu-button'
import Logo from '../logo'
import DesktopMenu from '../menu/desktop-menu'
import BroadcastBanner from '../broadcast-banner'
import type { Data } from '../layout/types'

type Props = {
  overlay: boolean,
  data: Data,
}

function Header({ overlay, data }: Props): Node {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [headerHeight, setHeaderHeight] = useState('5.5rem')
  const headerRef = useRef(null)

  const handleMenuChange = () => {
    setMenuIsOpen((prevState) => !prevState)
  }

  useEffect(() => {
    setHeaderHeight(headerRef.current && headerRef.current.clientHeight)
  }, [headerRef.current])

  return (
    <StyledContainer overlay={overlay} height={headerHeight}>
      <Helmet>
        <body className={cx('bg-purple', menuIsOpen && 'overflow-hidden')} />
      </Helmet>
      <StyledNav ref={headerRef} className="fixed pin-t pin-x z-50">
        <BroadcastBanner data={data} />
        <div className="flex justify-center text-white py-5 px-6">
          <div className="flex items-center w-full max-w-xl">
            <Link to="/" className="no-underline">
              <Logo />
            </Link>
            <div className="ml-auto">
              <div className="hidden lg:block">
                <DesktopMenu />
              </div>
              <div className="lg:hidden">
                <MenuButton onClick={handleMenuChange} />
              </div>
            </div>
          </div>
        </div>
      </StyledNav>
      <HeaderMenu isOpen={menuIsOpen} closeButton={handleMenuChange} />
    </StyledContainer>
  )
}

export default Header

const StyledNav = styled.nav`
  background-color: ${rgba(theme.colors.purple, 0.96)};
  box-shadow: 0 2px 10px ${rgba(theme.colors.black, 0.12)},
    0 1px 2px ${rgba(theme.colors.black, 0.12)};

  @supports (backdrop-filter: blur(1px)) {
    backdrop-filter: blur(8px);
    background-color: ${rgba(theme.colors.purple, 0.8)};
  }
`

const StyledContainer: ComponentType<{}> = styled.div`
  ${({ overlay, height }) =>
    !overlay &&
    height &&
    css`
      margin-bottom: ${typeof height === 'number' ? `${height}px` : height};
    `};
`
