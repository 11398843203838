// @flow

import { createContext, useContext } from 'react'

type PostNode = {
  node: {
    id: string,
    publishDate: string,
    slug: string,
    title: string,
  },
}

type AllPostsType = {
  edges: PostNode[],
}

export const AllPostsContext: React$Context<AllPostsType> =
  createContext<AllPostsType>({ edges: [] })

export const useAllPosts = (): AllPostsType => useContext(AllPostsContext)
