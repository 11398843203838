// @flow

import { map } from 'ramda'
import { provideBlogUrl } from './provideBlogUrl'

type PostNode = {
  node: {
    id: string,
    title: string,
    slug: string,
    publishDate: string,
  },
}

type Post = {
  id: string,
  title: string,
  url: string,
  publishDate: string,
}

export const formatAllPosts = (posts: PostNode[]): Array<Post> =>
  map((post) => {
    const { id, title, slug, publishDate } = post.node
    return {
      id,
      title,
      url: provideBlogUrl(slug),
      publishDate,
    }
  }, posts)

export default formatAllPosts
