// @flow

import { replace, split, join, toLower, compose } from 'ramda'
import { universalBtoa } from './idTools'
import type { Season } from '../types'

type SeasonPartial = {
  season: Season['season'],
  dateRange: Season['dateRange'],
  rates: Season['rates'],
}

const createId = ({
  season,
  dateRange,
}: {
  season: Season['season'],
  dateRange: Season['dateRange'],
}) => {
  const string = `${season}::${dateRange[0]}::${dateRange[1]}`
  const sanitizeAndEncrypt = compose(
    universalBtoa,
    toLower,
    join('-'),
    split(' '),
    replace(/\//gi, '-'),
  )
  return sanitizeAndEncrypt(string)
}

export const addSeasonIds = ({
  season,
  dateRange,
  rates,
}: SeasonPartial): Season => ({
  id: createId({ season, dateRange }),
  season,
  dateRange,
  rates,
})

export default addSeasonIds
