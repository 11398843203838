// @flow

export const renderPrice = (num: string): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
    .format(parseFloat(num))
    .replace(/\D00$/, '')

export default renderPrice
