// @flow

import React from 'react'
import type { Node, Element } from 'react'

import { map } from 'ramda'
import styled from 'styled-components'
import { Link } from 'gatsby'

import theme from '../../../tailwind'

import PillButtonLink from '../buttons/pill-button-link/pill-button-link'
import { menuItems } from './data/menu-items'

type Props = {
  children: Node,
  to: string,
  emphasized?: boolean,
}
function HeaderMenuLink({ children, to, emphasized }: Props) {
  if (emphasized) {
    return <StyledPillButtonLink to={to}>{children}</StyledPillButtonLink>
  }

  return (
    <StyledListItem>
      <Link to={to}>{children}</Link>
    </StyledListItem>
  )
}
HeaderMenuLink.defaultProps = {
  emphasized: false,
}

const renderMenuItem = ({ id, to, label, emphasized }) => (
  <HeaderMenuLink key={id} to={to} emphasized={emphasized}>
    {label}
  </HeaderMenuLink>
)

function DesktopMenu(): Element<'ul'> {
  return (
    <ul className="flex items-center text-right ml-auto">
      {map(renderMenuItem, menuItems)}
    </ul>
  )
}

export default DesktopMenu

const StyledPillButtonLink = styled(PillButtonLink)`
  font-size: ${theme.textSizes.sm};
  padding: 0.75rem 1rem !important;
`

const StyledListItem = styled.li`
  font-size: 0.875rem;

  a {
    color: white;
    text-decoration: none;
    transition: color 250ms ease-in-out;
    font-weight: 500;

    &:hover {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  &:not(:last-child) {
    margin-right: 0.75rem;
  }
`
