// @flow

import React, { useContext } from 'react'
import type { Element } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { BodyText } from '../typography'
import type { Data } from '../layout/types'
import theme from '../../../tailwind'
import { PostContext } from '../../context'

type Props = { data: Data }
function BroadcastBanner({ data }: Props): null | Element<'a'> {
  const post = useContext(PostContext)
  const { slug } = post
  const { broadcast } = data.site.siteMetadata
  const currentUrl = broadcast && broadcast.button.url.replace('/blog/', '')

  if (broadcast && slug !== currentUrl) {
    return (
      <a className="no-underline" href={broadcast.button.url}>
        <div className="bg-purple-darkest px-4 py-1">
          <div className="flex items-center max-w-xl mx-auto">
            <div className="w-full p-2">
              <BodyText className="text-yellow leading-tight pb-1">
                {broadcast.content}
              </BodyText>
              {broadcast.lastUpdate ? (
                <StyledDate className="text-xs text ">
                  Last updated: {broadcast.lastUpdate}
                </StyledDate>
              ) : null}
            </div>
            <FauxLink className="inline-flex bg-transparent text-purple-lightest p-2 sm:py-2 sm:px-4 rounded leading-tight text-center ml-auto whitespace-no-wrap">
              {broadcast.button.content}
            </FauxLink>
          </div>
        </div>
      </a>
    )
  }
  return null
}

export default BroadcastBanner

const FauxLink = styled(BodyText)`
  transition-property: color, background;
  transition-duration: 250ms;
  transition-timing-funtion: ease-in-out;

  &:hover {
    color: ${theme.colors['purple-lighter']};
    background: ${theme.colors['purple-darker']};
  }
`

const StyledDate = styled.p`
  color: ${rgba(theme.colors['purple-lightest'], 0.6)};
`
