// @flow

import type { Element, Node } from 'react'
import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'

import theme from '../../../tailwind'
import registrationNumbers from '../../data/registration-numbers'
import popularSearches from '../../data/popular-search-pages'
import { useAllPosts } from '../../context'
import { formatAllPosts } from '../../helpers'

import { InlineLink } from '../links'
import routes from '../../routes'
import { BodyText } from '../typography'

function FooterSectionTitle({ children }: { children: Node }) {
  return (
    <BodyText className="mb-4 font-semibold uppercase text-xs text-purple-lightest">
      {children}
    </BodyText>
  )
}

function FooterUnorderedList({
  children,
  className,
}: {
  children: Node,
  className?: string,
}) {
  return (
    <ul className={cx('list-reset leading-tight', className)}>{children}</ul>
  )
}
FooterUnorderedList.defaultProps = {
  className: undefined,
}

function FooterLink({
  children,
  to,
  className,
}: {
  children: Node,
  to: string,
  className?: string,
}) {
  return (
    <StyledListItem className={className}>
      <InlineLink color="text-purple-lighter" to={to}>
        {children}
      </InlineLink>
    </StyledListItem>
  )
}
FooterLink.defaultProps = {
  className: undefined,
}
const limitArray = (arr: any[], limit: number) => arr.slice(0, limit)
function Footer(): Element<'footer'> {
  const postLimit = 7
  const getDate = new Date()
  const renderYear = getDate.getFullYear()
  const allPosts = useAllPosts()
  const blogPosts = formatAllPosts(limitArray(allPosts.edges, postLimit))

  return (
    <footer
      id="footer"
      className="bg-purple-darkest text-grey text-sm px-8 py-12"
    >
      <div className="max-w-xl mx-auto">
        <div className="mb-6 md:flex md:-mx-4">
          <div className="md:px-4 w-full mb-6">
            <div className="mb-6">
              <FooterSectionTitle>Legal</FooterSectionTitle>
              <FooterUnorderedList className="mb-4">
                <>
                  <FooterLink to={routes.contact}>Contact</FooterLink>
                  <FooterLink to={routes.privacy}>Privacy Policy</FooterLink>
                </>
              </FooterUnorderedList>
            </div>
            <div>
              <FooterSectionTitle>STR Registration</FooterSectionTitle>
              <FooterUnorderedList>
                {registrationNumbers.map((num) => (
                  <StyledListItem key={`num-${num}`}>{num}</StyledListItem>
                ))}
              </FooterUnorderedList>
            </div>
          </div>
          {blogPosts.length !== 0 && (
            <div className="md:px-4 w-full mb-6">
              <FooterSectionTitle>Blog</FooterSectionTitle>
              <FooterUnorderedList>
                <>
                  {blogPosts.map(({ id, url, title }) => (
                    <FooterLink key={id} to={url}>
                      {title}
                    </FooterLink>
                  ))}
                  <FooterLink to={routes.blog}>View All</FooterLink>
                </>
              </FooterUnorderedList>
            </div>
          )}
          <div className="md:px-4 w-full mb-6">
            <FooterSectionTitle>Popular Searches</FooterSectionTitle>
            <FooterUnorderedList>
              {popularSearches.map(({ id, route, linkText }) => (
                <FooterLink key={id} to={route}>
                  {linkText}
                </FooterLink>
              ))}
            </FooterUnorderedList>
          </div>
          <div className="md:px-4 w-full">
            <FooterSectionTitle>Website Resources</FooterSectionTitle>
            <FooterUnorderedList>
              <>
                <FooterLink to={routes.links}>Resources</FooterLink>
                <FooterLink to={routes.faq}>FAQ</FooterLink>
              </>
            </FooterUnorderedList>
          </div>
        </div>
        <p className="text-purple text-xs">
          &copy; {renderYear} Cottages of Paradise Point
        </p>
      </div>
    </footer>
  )
}

export default Footer

const StyledListItem = styled.li`
  color: ${theme.colors['purple-lighter']};

  &:not(:last-child) {
    padding-right: 0.5rem;
    margin-bottom: 0.75rem;
  }
`
