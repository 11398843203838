// @flow

import type { Node } from 'react'
import React from 'react'

import { Heading2 } from '../typography'

function Logo(): Node {
  return (
    <Heading2 className="text-white uppercase">
      <div
        className="text-lg font-extrabold"
        style={{ letterSpacing: '0.15rem' }}
      >
        <div>Cottages</div>
        <div>of Paradise Point</div>
      </div>
    </Heading2>
  )
}

export default Logo
