// @flow

import React from 'react'
import type { Node, Element } from 'react'
import cx from 'classnames'

type Props = {
  children: Node,
  className?: string,
}

function Heading2({ children, className }: Props): Element<'h2'> {
  return (
    <h2 className={cx('font-heading font-bold text-2xl', className)}>
      {children}
    </h2>
  )
}
Heading2.defaultProps = {
  className: '',
}

export default Heading2
