// @flow

import type { Element, Node } from 'react'
import React from 'react'

type Props = {
  children: Node,
  className?: string,
}

function BodyText({ className, children }: Props): Element<'p'> {
  return <p className={className}>{children}</p>
}
BodyText.defaultProps = {
  className: undefined,
}

export default BodyText
