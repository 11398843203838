// @flow

import { split, join, compose, toLower, replace, map } from 'ramda'
import routes from '../../routes'

type PopularSearchItem = {
  id: string,
  route: string,
  linkText: string,
}

const addId = ({ route, linkText }) => {
  const splitText = split(' ')
  const joinText = join('-')
  const replaceNonAlpha = replace(/[\W_]+/g, ' ')
  const transformToKebabCase = compose(
    toLower,
    joinText,
    splitText,
    replaceNonAlpha,
  )

  return {
    id: transformToKebabCase(linkText),
    route,
    linkText,
  }
}

export const popularSearches: PopularSearchItem[] = map(addId, [
  {
    route: routes.seo.fortMyersBeachCottageRentals,
    linkText: 'Fort Myers Beach Cottage Rentals',
  },
  {
    route: routes.seo.ftMyersBeachCottageRentals,
    linkText: 'Ft. Myers Beach Cottage Rentals',
  },
  {
    route: routes.seo.fortMyersBeachRentals,
    linkText: 'Fort Myers Beach Rentals',
  },
  {
    route: routes.seo.fortMyersBeachRentalsByOwner,
    linkText: 'Fort Myers Beach Rentals By Owner',
  },
  {
    route: routes.seo.vacationInFlorida,
    linkText: 'Vacation in Florida',
  },
  {
    route: routes.seo.floridaVacationRentals,
    linkText: 'Florida Vacation Rentals',
  },
  {
    route: routes.seo.floridaCottages,
    linkText: 'Florida Cottages',
  },
])

export default popularSearches
