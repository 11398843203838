// @flow

import { map } from 'ramda'

import type {
  ContentfulCottagePageImage,
  ContentfulCottagePage,
  LegacyImage,
  Image,
} from '../types'

type CottagePageData = {
  id: string,
  title: string,
  heroImage: Image,
  cottageImages: LegacyImage[],
}

const transformCottageImagesToLegacyAPI = ({
  id,
  description,
  image,
}: ContentfulCottagePageImage) => ({
  id,
  image: { url: image.src, alt: '' },
  caption: description,
})

export const getFormattedData = ({
  id,
  title,
  featuredImage,
  cottageImages,
}: ContentfulCottagePage): CottagePageData => ({
  id,
  title,
  heroImage: {
    src: featuredImage.image.src,
    alt: featuredImage.description,
  },
  cottageImages: map(transformCottageImagesToLegacyAPI, cottageImages),
})

export default getFormattedData
