// @flow
import routes from '../../../routes'

export type MenuItem = {
  id: string,
  to: string,
  label: string,
}

export const menuItems: MenuItem[] = [
  {
    to: '/',
    label: 'Home',
  },
  {
    to: routes.cottages,
    label: 'Cottages',
  },
  {
    to: routes.info,
    label: 'Ft. Myers Beach Info',
  },
  {
    to: routes.paradisePoint,
    label: 'Paradise Point',
  },
  {
    to: routes.indulgences,
    label: 'Indulgences',
  },
  {
    to: routes.rates,
    label: 'Rates',
  },
  {
    to: routes.faq,
    label: 'FAQ',
  },
  {
    to: routes.links,
    label: 'Resources',
  },
  {
    to: routes.contact,
    label: 'Contact Us',
  },
  {
    to: routes.blog,
    label: 'Blog',
  },
].map((menuItem) => ({ id: menuItem.label.toLowerCase(), ...menuItem }))
