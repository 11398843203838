// @flow
import type { Element } from 'react' /* eslint-disable react/jsx-props-no-spreading */

import React from 'react'

type Props = {
  size?: number,
  color?: string,
}

function MenuIconIcon({ size, color, ...props }: Props): Element<'svg'> {
  return (
    <svg {...props} viewBox="0 0 30 14" width={size} height={size} fill={color}>
      <path d="M 0 0 H 30 V 2 H 0 z M 0 12 H 30 V 14 H 0 z" />
    </svg>
  )
}
MenuIconIcon.displayName = 'MenuIconIcon'
MenuIconIcon.defaultProps = {
  size: 24,
  color: 'currentcolor',
}

export default MenuIconIcon
