// @flow

import React, { useCallback } from 'react'
import type { Node, ComponentType } from 'react'

import { map } from 'ramda'

import styled, { keyframes } from 'styled-components'
import { rgba } from 'polished'

import theme from '../../../tailwind'

import HeaderLink from './header-link'
import { Heading3 } from '../typography'
import CloseIcon from '../icons/CloseIcon'
import { menuItems } from './data'

type HeaderMenuProps = {
  isOpen: boolean,
  closeButton: () => void,
}

type HeaderMenuLinkProps = {
  to: string,
  children: Node,
  onClick: () => void,
}

function HeaderMenuLink({ to, children, onClick }: HeaderMenuLinkProps) {
  return (
    <HeaderLink to={to} onClick={onClick}>
      {children}
    </HeaderLink>
  )
}

function HeaderMenu({ isOpen, closeButton }: HeaderMenuProps): Node {
  const renderMenuItem = useCallback(
    ({ id, to, label }) => (
      <HeaderMenuLink key={id} to={to} onClick={closeButton}>
        {label}
      </HeaderMenuLink>
    ),
    [closeButton],
  )
  return (
    <Container className="pin fixed z-1" isOpen={isOpen}>
      <Overlay className="p-4 h-full">
        <div className="max-w-xl mx-auto">
          <div className="w-full max-w-xs ml-auto">
            <MenuContainer className="bg-white rounded overflow-hidden">
              <div className="flex items-center pl-4 text-purple">
                <Heading3 className="font-semibold">Menu</Heading3>
                <div className="ml-auto">
                  <button
                    type="button"
                    className="text-purple px-4 py-5"
                    onClick={closeButton}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
              {menuItems.length > 0 ? (
                <menu className="p-0 my-0">
                  <ul className="list-reset">
                    {map(renderMenuItem, menuItems)}
                  </ul>
                </menu>
              ) : (
                'Error loading menu'
              )}
            </MenuContainer>
          </div>
        </div>
      </Overlay>
    </Container>
  )
}

export default HeaderMenu

const fadeInDown = keyframes`
  from {
    transform: scale(1.02) translateY(-20px);
    opacity: 0;
  }

  to {
    transform: scale(1) translateY(1);
    opacity: 1;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Container: ComponentType<{}> = styled.div`
  ${({ isOpen }) => (isOpen ? 'display: block;' : 'display: none;')};
  transition: all 250ms ease-in-out;
  z-index: 1000;
`

const Overlay = styled.div`
  backdrop-filter: blur(3px);
  animation-name: ${fadeIn};
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

  @media screen and (max-width: ${theme.screens.md}) {
    background: ${rgba(theme.colors.indigo, 0.8)};
    backdrop-filter: blur(5px);
  }
`

const MenuContainer = styled.div`
  animation-name: ${fadeInDown};
  animation-duration: 300ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

  @media screen and (min-width: ${theme.screens.md}) {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  }
`
