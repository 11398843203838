// @flow

import type { Node } from 'react'
import type { OverviewItem } from '../types'

export const generateIdFromTitle = ({
  title,
  message,
}: {
  title: string,
  message: string | Node,
}): OverviewItem => ({
  title,
  message,
  id: String(title).split(' ').join('-').toLowerCase(),
})

export default generateIdFromTitle
