// @flow

import React from 'react'
import type { Node } from 'react'

import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import type { Data, SiteData } from './types'
import { AllPostsContext } from '../../context'

import Header from '../header'
import Footer from '../footer'

import '../../styles/base/base.css'

type CoreTemplateProps = {
  children: Node,
  overlayHeader?: boolean,
  data: Data,
}

const findImageType =
  (targetType) =>
  ({ type }) =>
    type === targetType

const openGraph = ({
  siteMetadata: { title, description, openGraphType, siteUrl, metaMedia },
}: SiteData) => {
  const pngImage = metaMedia.find(findImageType('image/png'))
  if (!pngImage) {
    throw new Error('Unable to load a png image from gatsby-config.js')
  }

  return [
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: openGraphType,
    },
    {
      property: 'og:url',
      content: siteUrl,
    },
    {
      property: 'og:image',
      content: pngImage.uri,
    },
    {
      property: 'og:image:alt',
      content: pngImage.alt,
    },
  ]
}

const meta = (siteConfigData: SiteData) => [
  ...openGraph(siteConfigData),
  {
    name: 'google-site-verification',
    content: 'R3S0Dt44V-rIe2oAJeQ2zJAbH9ipl2k-CMXtrCKGUqo',
  },
]

function CoreTemplate({ data, children, overlayHeader }: CoreTemplateProps) {
  const { title } = data.site.siteMetadata
  return (
    <div className="bg-white">
      <Helmet
        titleTemplate={`%s | ${title}`}
        defaultTitle={title}
        meta={meta(data.site)}
      />
      <React.StrictMode>
        <Header data={data} overlay={overlayHeader || false} />
        <div id="page-body">{children}</div>
        <Footer />
      </React.StrictMode>
    </div>
  )
}
CoreTemplate.defaultProps = {
  overlayHeader: false,
}

type LayoutProps = {
  children: Node,
  overlayHeader?: boolean,
}

type PureLayoutProps = LayoutProps & {
  data: any,
}

export function PureLayout({
  data,
  children,
  overlayHeader,
}: PureLayoutProps): Node {
  return (
    <AllPostsContext.Provider value={data.allContentfulBlogPost}>
      <CoreTemplate data={data} overlayHeader={overlayHeader}>
        {children}
      </CoreTemplate>
    </AllPostsContext.Provider>
  )
}
PureLayout.defaultProps = {
  overlayHeader: false,
}

function Layout({ overlayHeader, children }: LayoutProps): Node {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
              description
              siteUrl
              openGraphType
              broadcast {
                type
                content
                lastUpdate
                button {
                  content
                  url
                }
              }
              metaMedia {
                uri
                type
                alt
              }
            }
          }
          allContentfulBlogPost {
            edges {
              node {
                id
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
              }
            }
          }
        }
      `}
      render={(data) => (
        <PureLayout data={data} overlayHeader={overlayHeader}>
          {children}
        </PureLayout>
      )}
    />
  )
}
Layout.defaultProps = {
  overlayHeader: false,
}

export default Layout
