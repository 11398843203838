// @flow

import React from 'react'
import type { Node, Element } from 'react'
import cx from 'classnames'

type Props = {
  children: Node,
  className?: string,
}

function Heading3({ children, className }: Props): Element<'h3'> {
  return (
    <h3 className={cx('font-heading font-bold text-xl', className)}>
      {children}
    </h3>
  )
}
Heading3.defaultProps = {
  className: '',
}

export default Heading3
