// @flow

import * as React from 'react'
import type { Node, ComponentType } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { Link } from 'gatsby'
import theme from '../../../tailwind'

type Props = {
  to: string,
  onClick: () => void,
  children: Node,
}

function HeaderLink({ children, to, onClick }: Props): React.Node {
  return (
    <StyledListItem className="font-heading">
      <StyledLink to={to} onClick={onClick}>
        {children}
      </StyledLink>
    </StyledListItem>
  )
}

export default HeaderLink

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${theme.colors['grey-darker']};
  padding: 0.75rem 1rem;

  @media (min-width: ${theme.screens.sm}) {
    padding: 1rem;
  }
`
const StyledListItem: ComponentType<{}> = styled.li`
  list-style: none;
  border-left-style: solid;
  border-left-width: 3px;
  font-weight: 600;
  margin-bottom: 1px;
  transition: background-color 200ms ease-in-out;

  &:nth-child(odd) {
    background-color: ${theme.colors['grey-lightest']};

    @media (min-width: ${theme.screens.sm}) {
      &:hover {
        background: ${darken(0.075, theme.colors['grey-lightest'])};
      }
    }
  }

  &:nth-child(even) {
    background-color: ${theme.colors.white};

    @media (min-width: ${theme.screens.sm}) {
      &:hover {
        background: ${darken(0.075, theme.colors.white)};
      }
    }
  }

  &:nth-child(7n + 1) {
    border-left-color: ${theme.colors.orange};
  }

  &:nth-child(7n + 2) {
    border-left-color: ${theme.colors.green};
  }

  &:nth-child(7n + 3) {
    border-left-color: ${theme.colors.yellow};
  }

  &:nth-child(7n + 4) {
    border-left-color: ${theme.colors['indigo-lightest']};
  }

  &:nth-child(7n + 5) {
    border-left-color: ${theme.colors.indigo};
  }

  &:nth-child(7n + 6) {
    border-left-color: ${theme.colors.salmon};
  }

  &:nth-child(7n + 7) {
    border-left-color: ${theme.colors['bright-purple']};
  }
`
