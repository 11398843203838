// @flow
import type { Element } from 'react' /* eslint-disable react/jsx-props-no-spreading */

import React from 'react'

type Props = {
  size?: number,
  color?: string,
}

function CloseIconIcon({ size, color, ...props }: Props): Element<'svg'> {
  return (
    <svg {...props} viewBox="0 0 22 22" width={size} height={size} fill={color}>
      <path d="M9.586 11L.393 1.808A1 1 0 1 1 1.808.393L11 9.586 20.192.393a1 1 0 1 1 1.415 1.415L12.414 11l9.193 9.192a1 1 0 1 1-1.415 1.415L11 12.414l-9.192 9.193a1 1 0 0 1-1.415-1.415L9.586 11z" />
    </svg>
  )
}

CloseIconIcon.displayName = 'CloseIconIcon'
CloseIconIcon.defaultProps = {
  size: 24,
  color: 'currentcolor',
}

export default CloseIconIcon
