// @flow

import React from 'react'
import type { Node, ComponentType } from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { rgba } from 'polished'

import theme from '../../../../tailwind'

type Props = {
  to: string,
  children: Node,
  className?: string,
  color?: string,
}

function InlineLink({ to, children, className, color }: Props): Node {
  return (
    <StyledLink
      to={to}
      className={cx('no-underline', color || 'text-grey', className)}
    >
      {children}
    </StyledLink>
  )
}
InlineLink.defaultProps = {
  className: '',
  color: undefined,
}

const StyledLink: ComponentType<{}> = styled(Link)`
  border-bottom: 1px solid ${rgba(theme.colors['purple-light'], 0)};
  transition: all 250ms ease-in-out;

  &:hover {
    color: ${theme.colors['purple-light']};
    border-bottom-color: ${rgba(theme.colors['purple-light'], 0.5)};
  }

  &:active {
    color: ${theme.colors['purple-lighter']};
    border-bottom-color: ${rgba(theme.colors['purple-lighter'], 0.5)};
  }
`

export default InlineLink
