// @flow

const registrationNumbers: string[] = [
  '19-1182',
  '19-1184',
  '19-1185',
  '19-1186',
  '19-1187',
  '19-1188',
  '19-1189',
]

export default registrationNumbers
