// @flow

import React from 'react'
import type { Node, ComponentType } from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { rgba } from 'polished'

import theme from '../../../../tailwind'

type Props = {
  href: string,
  children: Node,
  className?: string,
}

function MediaLink({ href, children, className }: Props): Node {
  return (
    <StyledAnchor
      href={href}
      className={cx(
        'inline-flex text-purple no-underline border-b border-solid',
        className,
      )}
    >
      {children}
    </StyledAnchor>
  )
}
MediaLink.defaultProps = {
  className: undefined,
}

export default MediaLink

const StyledAnchor: ComponentType<{}> = styled.a`
  border-bottom-color: ${rgba(theme.colors.purple, 0.6)};
  transition-duration: 250ms;
  transition-property: color, border;
  transition-timing-function: ease-in-out;

  &:hover {
    border-bottom-color: ${rgba(theme.colors.purple, 1)};
    color: ${theme.colors['purple-dark']};
  }

  &:active {
    color: ${theme.colors['purple-darker']};
  }
`
