// @flow

import * as React from 'react'
import type { Node, ComponentType } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import cx from 'classnames'
import { rgba } from 'polished'

type Props = {
  to: string,
  children: Node,
  color?: string,
  className?: string,
}

function PillButtonLink({ to, children, color, className }: Props): React.Node {
  return (
    <StyledLink
      className={cx(
        'px-8 py-4 block rounded-full text-center no-underline font-semibold font-sm',
        color === 'salmon' ? 'text-white' : 'black',
        color && `bg-${color}`,
        className,
      )}
      to={to}
    >
      {children}
    </StyledLink>
  )
}
PillButtonLink.defaultProps = {
  color: 'salmon',
  className: '',
}

export default PillButtonLink

const StyledLink: ComponentType<{}> = styled(Link)`
  text-shadow: 0 1px 0 ${rgba('#000', 0.05)};
  box-shadow: 0 2px 4px ${rgba('#000', 0.11)},
    inset 0 1px 2px ${rgba('#fff', 0.19)};
`
