// @flow

export default {
  contact: '/contact-us',
  cottages: '/cottages',
  faq: '/faq',
  homepage: '/',
  indulgences: '/indulgences',
  info: '/ft-myers-beach-info',
  links: '/links',
  listings: {
    gulf: '/cottages/gulf-cottage',
    island: '/cottages/island-cottage',
    sun: '/cottages/sun-cottage',
    seahorse: '/cottages/seahorse-cottage',
    mermaid: '/cottages/mermaid-cottage',
    dolphin: '/cottages/dolphin-cottage',
    coconut: '/cottages/coconut-cottage',
  },
  paradisePoint: '/paradise-point',
  privacy: '/privacy-policy',
  rates: '/rates',
  seo: {
    fortMyersBeachCottageRentals: '/fort-myers-beach-cottage-rentals',
    ftMyersBeachCottageRentals: '/ft-myers-beach-cottage-rentals',
    fortMyersBeachRentals: '/fort-myers-beach-rentals',
    fortMyersBeachRentalsByOwner: '/fort-myers-beach-rentals-owner',
    vacationInFlorida: '/vacation-in-florida',
    floridaVacationRentals: '/florida-vacation-rentals',
    floridaCottages: '/florida-cottages',
  },
  blog: '/blog',
}
