// @flow

import { map } from 'ramda'
import routes from '../../../routes'

type MenuItemData = {
  label: string,
  to: string,
  emphasized?: boolean,
}

export type MenuItem = {
  id: string,
  label: string,
  to: string,
  emphasized?: boolean,
}

const addIdToMenuItem = (menuItem: MenuItemData) => ({
  ...menuItem,
  id: menuItem.label.toLowerCase(),
})

const menuItemsData: MenuItemData[] = [
  {
    to: routes.cottages,
    label: 'Cottages',
  },
  {
    to: routes.info,
    label: 'Ft. Myers Beach',
  },
  {
    to: routes.paradisePoint,
    label: 'Paradise Point',
  },
  {
    to: routes.indulgences,
    label: 'Indulgences',
  },
  {
    to: routes.rates,
    label: 'Rates',
  },
  {
    to: routes.faq,
    label: 'FAQ',
  },
  {
    to: routes.links,
    label: 'Resources',
  },
  {
    to: routes.contact,
    label: 'Contact Us',
    emphasized: true,
  },
]

export const menuItems: MenuItem[] = map(addIdToMenuItem, menuItemsData)
