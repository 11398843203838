// @flow

import React from 'react'
import type { Node, Element } from 'react'
import cx from 'classnames'

type Props = {
  children: Node,
  className?: string,
}

function Heading4({ children, className }: Props): Element<'h4'> {
  return (
    <h4 className={cx('font-heading font-bold text-lg', className)}>
      {children}
    </h4>
  )
}
Heading4.defaultProps = {
  className: '',
}

export default Heading4
