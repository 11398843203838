// @flow

import React from 'react'
import type { Node, Element } from 'react'
import cx from 'classnames'

type Props = {
  children: Node,
  className?: string,
  isTitle?: boolean,
}

const styles = {
  base: 'font-heading font-extrabold text-3xl',
}

function Heading1({
  isTitle,
  children,
  className,
}: Props): Element<'h1'> | Element<'h2'> {
  return isTitle ? (
    <h1 className={cx(styles.base, className)}>{children}</h1>
  ) : (
    <h2 className={cx(styles.base, className)}>{children}</h2>
  )
}
Heading1.defaultProps = {
  isTitle: false,
  className: '',
}

export default Heading1
